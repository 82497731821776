<template>
  <div>
    <v-card style="padding: 10px">
      <v-form>
        <v-row>
          <v-col cols="12" md="3">
            <label for="product_name">Service Name</label>
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field
              id="product_name"
              v-model="product_name"
              outlined
              dense
              placeholder="What is the Service's Name?"
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="sell_price">Price</label>
          </v-col>

          <v-col cols="12" md="9">
            <v-text-field
              id="sell_price"
              v-model="sell_price"
              outlined
              dense
              placeholder="What's the Price of this Service?"
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card class="mx-auto mt-10" outlined>
          <div data-v-6fcca708="" class="d-flex justify-space-between flex-wrap pt-12">
            <div data-v-6fcca708="" class="me-2 mb-2">
              <div data-v-6fcca708="" class="v-card__title pt-0 px-0">Bind Products</div>
            </div>
            <button
              data-v-6fcca708=""
              v-on:click="
                stocks.push({
                  id: Math.random() * 10000,
                  product: products[0],
                  amount: 0,
                })
              "
              type="button"
              class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary"
            >
              <span class="v-btn__content">add</span>
            </button>
          </div>
          <v-simple-table height="auto" max-height="500" fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center text-uppercase">Product</th>
                  <th class="text-center text-uppercase">Amount</th>
                  <th class="text-center text-uppercase">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="stock in stocks" :key="stock.id">
                  <td class="text-center" style="padding: 10px">
                    <v-select dense outlined class="shrink" :items="products.map(a => a.name)"></v-select>
                  </td>

                  <td class="text-center" style="padding: 10px">
                    <v-text-field
                      label="Amount"
                      v-model="stock['amount']"
                      :suffix="stock.product.measurement_name"
                      outlined
                      dense
                      type="number"
                    ></v-text-field>
                  </td>

                  <td class="text-center">
                    <div
                      v-on:click="stocks = stocks.filter(item => item !== stock)"
                      class="v-avatar elevation-1 rounded error"
                      style="height: 30px; min-width: 30px; width: 30px"
                    >
                      <span
                        aria-hidden="true"
                        class="v-icon notranslate theme--dark white--text"
                        style="font-size: 25px; height: 25px; width: 25px"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          role="img"
                          aria-hidden="true"
                          class="v-icon__svg"
                          style="font-size: 25px; height: 25px; width: 25px"
                        >
                          <path :d="icons.mdiCloseCircleOutline"></path></svg
                      ></span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <v-col offset-md="3" cols="12">
          <v-btn color="primary" v-on:click="submit"> Submit </v-btn>
          <v-btn v-on:click="cancel" type="reset" class="mx-2" outlined> Cancel </v-btn>
        </v-col>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import axios from 'axios'
import { mdiPlus, mdiCloseCircleOutline } from '@mdi/js'

export default {
  methods: {
    measurement_selected(measurement_name) {
      for (var i = 0; i < this.measurements.length; i++) {
        if (this.measurements[i].name == measurement_name) {
          this.measurement_id = this.measurements[i].id
        }
      }
    },
    cancel: function (_) {
      this.$router.go(-1)
    },
    submit: function (_) {
      var _stocks = []
      for (var i = 0; i < this.stocks.length; i++) {
        _stocks.push({ amount: parseFloat(this.stocks[i].amount), id: parseInt(this.stocks[i].product.id) })
      }
      axios({
        url: `https://invictsoft.com/api/insert_new_service?name='${this.product_name}'&price=${parseFloat(
          this.sell_price,
        )}&stocks=${JSON.stringify(_stocks)}&id_token=${this.$cookies.get('login')}`,
      }).then(response => {
        console.log(response)
        this.$router.push({ name: 'services' })
      })
    },
  },
  async created() {
    this.$store.state.loading = true
    this.products = (
      await axios({
        url: `https://invictsoft.com/api/get_products?id_token=${this.$cookies.get('login')}`,
      })
    ).data
    this.measurements = (
      await axios({
        url: `https://invictsoft.com/api/get_measurements?id_token=${this.$cookies.get('login')}`,
      })
    ).data

    this.$store.state.loading = false
  },
  data() {
    return {
      //this list contains the raw materials that the user have chosen
      measurements: [],
      measurement_id: 0,
      key: 0,
      stocks: [],
    }
  },
  setup() {
    const product_name = ref('')
    const leaflet_key = ref('')
    const inventory_address = ref('')
    const sell_price = ref('')
    const buy_price = ref('')
    const amount = ref('')
    return {
      icons: {
        mdiPlus,
        mdiCloseCircleOutline,
      },
      product_name,
      leaflet_key,
      inventory_address,
      sell_price,
      buy_price,
      amount,
    }
  },
}
</script>
<style scoped>
@import url('https://unpkg.com/leaflet@1.6.0/dist/leaflet.css');
.component-container {
  display: table;
  @media (pointer: none), (pointer: coarse) {
    width: calc(100% - (50%));
  }
}
.component-row {
  display: table-row;
}
.component-row div {
  position: relative;
  display: table-cell;
}
.component-row div:nth-child(2) {
  left: 20px;
}
.component-row div:nth-child(3) {
  left: 40px;
}
</style>